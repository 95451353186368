<template>
  <div>
    <PageHeader></PageHeader>
    <b-row>
      <b-col cols="12" md="8">
        <b-row>
          <b-col>
            <label>ประเภทบัญชี</label>
            <br />
            <b-form-radio-group
              v-model="selectedAccountType"
              button-variant="outline-dark"
              buttons
            >
              <b-form-radio
                v-for="(item, index) in filteredAccountTypes"
                :key="index"
                :value="item.type"
                >{{ item.name }}
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
          <b-col>
            <label>สถานะ</label>
            <b-select v-if="!isLoading" v-model="statusSelected">
              <b-form-select-option
                v-for="(opt, key) in statusOption"
                :key="key"
                :value="opt.value"
                >{{ opt.label }}
              </b-form-select-option>
            </b-select>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="3" offset-md="1" class="text-right">
        <b-form-group class="d-flex align-items-end justify-content-end">
          <br />
          <day-picker @update="(e) => (selectedDate = e)"></day-picker>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-card>
        <!-- Table -->
        <b-table
          :items="data.data"
          :busy="isLoading || data == null"
          responsive
          show-empty
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{
              $t('messages.nothing_here')
            }}</p>
          </template>
        </b-table>
        <!-- pagination -->
      <PaginationInput :per-page="limit" :total="data.totalItems" @update="(val) => page = val"/>
      </b-card>
    </div>
  </div>
</template>

<script>
import CoreBankService from '../../../services/bank'
import dayjs from 'dayjs'

export default {
  page: {
    title: 'Raw Transaction Banking',
  },
  data() {
    return {
      filteredMenu: [],
      selectedDate: new Date(),
      statusSelected: false,
      filteredAccountTypes: [
        {
          type: 'ฝาก',
          name: 'ฝาก',
        },
        {
          type: 'ฝากและถอน',
          name: 'ฝากและถอน',
        },
        {
          type: 'ถอน',
          name: 'ถอน',
        },
      ],
      isLoading: false,
      statusOption: [
        {
          label: 'Is Sync',
          value: true,
        },
        {
          label: 'Not Sync',
          value: false,
        },
      ],
      selectedAccountType: 'ฝาก',
      data: {
        limit: '50',
        page: '1',
        totalItemOnPage: 0,
        totalItems: 0,
        totalPages: 0,
      },
      page: 1,
      limit: 50,
    }
  },
  watch: {
    selectedDate(val) {
      if (val) {
        this.fetchData()
      }
    },
    selectedAccountType(val) {
      if (val) this.fetchData()
    },
    statusSelected() {
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      try {
        this.data = await CoreBankService.fetchRawTxn({
          page: this.page,
          limit: this.limit,
          transaction_description: this.selectedAccountType,
          order: 'DESC',
          date: dayjs(this.selectedDate).format('YYYY-MM-DD'),
          is_sync: this.statusSelected,
        })
      } catch (e) {
        await this.$store.dispatch('notifyError', e)
      }
      this.isLoading = false
    },
  },
}
</script>
